<template>
  <div class="print-publications masonry-layout">
    <div
      v-for="publication in publications"
      :key="publication.name"
      class="publication"
    >
      <img
        :src="require(`@/assets/images/Publications/${publication.image}`)"
        class="publication-image"
      />
      <h4 class="article-title smallmargintopnomarginbottom">
        {{ publication.name }}
      </h4>

      <div class="read-more publication-info">
        <p class="publisher-info">
          {{ publication.publisher }}
          <a
            v-if="publication.link !== ''"
            :href="publication.link"
            target="_blank"
          >
            More
            <svg class="microSvg">
              <use
                xlink:href="@/assets/icons/iconset.svg#microExternalLink"
              ></use>
            </svg>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import publicationData from "@/components/InsightsComponents/publications.json"; // Adjust the path to the location of your publications.json

export default {
  name: "PrintPublications",
  setup() {
    const publications = ref(publicationData);

    return {
      publications,
    };
  },
};
</script>

<style scoped>
.publication-image {
  opacity: 0.9;
}
</style>
