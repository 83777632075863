<template>
  <div class="page">
    <p>
      <strong>Hands-on graphic materials since 2004</strong>
    </p>
    <hr />

    <h3>Logos</h3>
    <p>A selection of logos for clients in different sectors of activity</p>

    <div class="row logoRow spaceBetween largerWidth">
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/GraphicDesign/logos/cuir.png"
          alt="cuir logo"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour smallerHeight"
          src="@/assets/images/GraphicDesign/logos/circular.png"
          alt="circular logo"
        />
        <figcaption></figcaption>
      </figure>
    </div>

    <div class="row logoRow spaceBetween">
      <figure>
        <img
          class="small invertedColour largestWidth"
          src="@/assets/images/GraphicDesign/logos/unbottled.png"
          alt="canaldeicuori logo"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour largerWidth"
          src="@/assets/images/GraphicDesign/logos/footfall.png"
          alt="footfall logo"
        />
        <figcaption></figcaption>
      </figure>
    </div>
    <div class="row logoRow spaceBetween">
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/GraphicDesign/logos/bNC.png"
          alt="bNC logo"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/GraphicDesign/logos/cardipril.png"
          alt="cardipril logo"
        />
        <figcaption></figcaption>
      </figure>
    </div>

    <div class="row logoRow spaceBetween">
      <figure>
        <img
          class="small invertedColour largerWidth"
          src="@/assets/images/GraphicDesign/logos/euroboccia.png"
          alt="euroboccia logo"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/GraphicDesign/logos/opta.png"
          alt="opta logo"
        />
        <figcaption></figcaption>
      </figure>
    </div>

    <!--  <div class="row logoRow spaceBetween">
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/GraphicDesign/logos/douro.png"
          alt="douro logo"
        />
        <figcaption></figcaption>
      </figure>
       <figure>
        <img
          class="small invertedColour largerWidth"
          src="@/assets/images/GraphicDesign/logos/unbottled.png"
          alt="unbottled logo"
        />
        <figcaption></figcaption>
      </figure>
      
    </div>-->
    <!-- <div class="row logoRow spaceBetween">
      <figure>
        <img
          class="small invertedColour largerWidth"
          src="@/assets/images/GraphicDesign/logos/dessau.png"
          alt="dessau logo"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour largerWidth"
          src="@/assets/images/GraphicDesign/logos/oolong.png"
          alt="oolong logo"
        />
        <figcaption></figcaption>
      </figure>
    </div>-->
    <hr />
    <div class="Circular Materials">
      <h3>Circular 2024 – Performative Arts Festival yearly festival</h3>
      <p>
        The Circular Festival in Vila do Conde is an annual event showcasing
        contemporary performing arts, with a mix of dance, theatre, music, and
        interdisciplinary works from around the world.
      </p>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/GraphicDesign/circular/Circular2024.gif"
          alt="Banner for Circular 2024"
        />
        <figcaption></figcaption>
      </figure>
      <div class="rowOnDesktop spaceBetween">
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/GraphicDesign/circular/Circ2024/circ24_iphone_story.png"
          alt="Instagram story for Circular, 2022"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/GraphicDesign/circular/Circ2024/circ24_iphone_posts.png"
          alt="Creating an organisation within Unit's webapp, 2023"
        />
        <figcaption></figcaption>
      </figure>
    </div>
      <figure>
      <img
        class="invertedColour"
        src="@/assets/images/GraphicDesign/circular/Circ2024/circ24_desktop_facebook.png"
        alt="Facebook banner for Circular 2024"
      />
      <figcaption></figcaption>
    </figure>
  <!--  <figure>
      <img
        class="invertedColour"
        src="@/assets/images/GraphicDesign/circular/Circ2024/circ24_desktop_website.png"
        alt="Facebook banner for Circular 2024"
      />
      <figcaption></figcaption>
    </figure>-->
   

      <h3>Circular 2023</h3>

      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/GraphicDesign/circular/Circular2023_banner.jpg"
          alt="Banner for Circular 2023"
        />
        <figcaption></figcaption>
      </figure>
      <div class="rowOnDesktop spaceBetween circular">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2023_red.jpg"
            alt="Banner for Circular 2023"
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2023_blue.jpg"
            alt="Banner for Circular 2023"
          />
          <figcaption></figcaption>
        </figure>
      </div>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/GraphicDesign/circular/Circular2023_wall.jpg"
          alt="Banner for Circular 2023"
        />
        <figcaption></figcaption>
      </figure>
    </div>

    <div class="Circular">
      <h3>Circular various editions</h3>
      <div class="rowOnDesktop spaceBetween">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2023.gif"
            alt="Circular 2023"
          />
          <figcaption>Poster Circular 2023</figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2021.gif"
            alt="Circular 2021"
          />
          <figcaption>Poster Circular 2021</figcaption>
        </figure>
      </div>

      <!-- <div class="rowOnDesktop spaceBetween">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2014.jpg"
            alt="Circular 2014"
          />
          <figcaption>Poster Circular 2014</figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2015.jpg"
            alt="Circular 2015"
          />
          <figcaption>Poster Circular 2015</figcaption>
        </figure>
      </div> -->
      <!-- <div class="rowOnDesktop spaceBetween circular">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2015.jpg"
            alt="Circular 2015"
          />
          <figcaption>Poster Circular 2015</figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2016.jpg"
            alt="Circular 2016"
          />
          <figcaption>Poster Circular 2016</figcaption>
        </figure>
      </div>-->
      <div class="rowOnDesktop spaceBetween circular">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2020.gif"
            alt="Circular 2014"
          />
          <figcaption>Poster Circular 2020</figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2019.gif"
            alt="Circular 2019"
          />
          <figcaption>Poster Circular 2019</figcaption>
        </figure>
      </div>
      <div class="rowOnDesktop spaceBetween circular">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2018.gif"
            alt="Circular 2018"
          />
          <figcaption>Poster Circular 2018</figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2017.gif"
            alt="Circular 2017"
          />
          <figcaption>Poster Circular 2017</figcaption>
        </figure>
      </div>

      <div class="rowOnDesktop spaceBetween nomargintop circular">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2016.jpg"
            alt="Circular 2016"
          />
          <figcaption>Poster Circular 2016</figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/circular/Circular2012.jpg"
            alt="Circular 2012"
          />
          <figcaption>Poster Circular 2012</figcaption>
        </figure>
      </div>
    </div>

    <hr />
    <div class="CampTabac">
      <h3>Campanhã & Tabacaria – Art galery</h3>
      <p>
        Campanhã & Tabacaria brand consited in defining 2 distinct identies for
        two different art projects managed the same organisation. One of the
        galleries was only dedicated to art made on paper, and video-art. Our
        work focused on creating distinct looks for each format, circle and dark
        green for video, and diamond and yellow for paper.
      </p>
      <div class="rowOnDesktop spaceBetween circular">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/tabacaria_campanha/Logos_campanh.jpg"
            alt="Logo for Campanhã"
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/tabacaria_campanha/Logos_tabacaria.jpg"
            alt="Logo for Tabacaria"
          />
          <figcaption></figcaption>
        </figure>
      </div>
    </div>

    <div class="rowOnDesktop spaceBetween circular">
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterJan_1_AdV_web.jpg"
          alt="Logo for Tabacaria"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterAbr_GdP.jpg"
          alt="Logo for Campanhã"
        />
        <figcaption></figcaption>
      </figure>
    </div>
    <div class="rowOnDesktop spaceBetween circular">
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterJun_AdV.jpg"
          alt="Logo for Tabacaria"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterJun_GdP.jpg"
          alt="Logo for Campanhã"
        />
        <figcaption></figcaption>
      </figure>
    </div>
    <div class="rowOnDesktop spaceBetween tabacaria">
      <div class="row subRow spaceBetween">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterJul_AdV.jpg"
            alt="Poster for Tabacaria"
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterSet_AdV.jpg"
            alt="Poster for Tabacaria"
          />
          <figcaption></figcaption>
        </figure>
      </div>
      <div class="row subRow spaceBetween">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterDez_1_GdP_web.jpg"
            alt="Poster for Tabacaria"
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/GraphicDesign/tabacaria_campanha/PosterMar_2_GdP.jpg"
            alt="Poster for Tabacaria"
          />
          <figcaption></figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
};
</script>

<style scoped>
.row {
  align-items: flex-start;
  gap: var(--smallMargin);
}

.circular figure {
  width: 50%;
}
.row.spaceBetween.logoRow {
  display: flex; /* Display as flex container */
  justify-content: space-around; /* Space out the figures */
  gap: var(--largerMargin);
  align-items: center;
  margin: calc(var(--largerMargin) * 4.2) 0;
}

.logoRow figure {
  max-width: 160px;
  display: flex; /* Display as flex container */
  flex-direction: column; /* Stack img and figcaption vertically */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  opacity: 0.7;
}

.logoRow img {
  /* Ensure your images are styled appropriately, possibly with max-width or width properties */
  max-width: 100px;
  max-height: 90px;
  border-radius: 0px !important;
}

.logoRow img.largerWidth {
  /* Ensure your images are styled appropriately, possibly with max-width or width properties */
  max-width: 115px;
  max-height: 90px;
  border-radius: 0px !important;
}
.logoRow img.largestWidth {
  /* Ensure your images are styled appropriately, possibly with max-width or width properties */
  max-width: 150px;
  max-height: 90px;
  border-radius: 0px !important;
}

.logoRow img.smallerHeight {
  max-height: 77px;
}

.rowOnDesktop .subRow {
  width: 50%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 758px) {
  .circular figure {
    width: 100%;
  }
  .logoRow img.largestWidth {
    /* Ensure your images are styled appropriately, possibly with max-width or width properties */
    max-width: 140px;
    max-height: 90px;
    border-radius: 0px !important;
  }
  .logoRow img.largerWidth {
    /* Ensure your images are styled appropriately, possibly with max-width or width properties */
    max-width: 105px;
    max-height: 90px;
    border-radius: 0px !important;
  }
  .logoRow img.smallerHeight {
    max-height: 75px;
  }
}
/* Add styles specific to this component */
</style>
