import { createStore } from "vuex";

export default createStore({
  state: {
    isNavVisible: true,
  },
  getters: {},
  mutations: {
    setNavVisibility(state, isVisible) {
      state.isNavVisible = isVisible;
    },
  },
  actions: {
    // toggleSideMenu({ commit }, isVisible) {
    //   commit("setNavVisibility", isVisible);
    // },
  },
  modules: {},
});
