<template>
  <div class="page">
    <p>
      <strong>
        A healthy selection of failed enterpreneurial projects, sucessful
        collaborations, and client work.
      </strong>
    </p>
    <hr />

    <div class="footfall">
      <h3>Footfall - foot traffic monitoring</h3>
      <p>
        From 2018 to 2019, I partnered with Ben Marland to develop Footfall, an
        app paired with IoT hardware to measure foot traffic. Focused on
        multi-room buildings, the system provided accurate data while adhering
        to GDPR standards, ensuring user privacy.
      </p>

      <p>
        I designed the brand, created the business model, and designed and
        developed (front-end and back-end), allowing users to monitor their
        spaces' foot traffic in real-time and access historical data to compare
        foot traffic between different times. Ben prototyped the IoT hardware to
        a 95%-97% accuracy in foot traffic monitoring.
      </p>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/Solopreneur/footfall_desktop_1.png"
          alt="Image of desktop, mobile app, asn IoT sensors. Footfall – an easy way to know how many people are your spaces"
        />
        <figcaption>Retail analytics for space management</figcaption>
      </figure>
      <figure class="nomarginbottom">
        <img
          class="invertedColour"
          src="@/assets/images/Solopreneur/footfall_tablet_1.png"
          alt="A privacy-centric solution ensuring user anonymity."
        />
        <figcaption>
          We designed a privacy-centric solution ensuring user anonymity.
        </figcaption>
      </figure>
      <!-- MObile images -->
      <div class="row spaceBetween nomargintop">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/footfall_mob_1.png"
            alt="Footfall – "
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/footfall_mob_2.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/footfall_mob_3.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
      </div>
    </div>
    <hr />
    <div class="floatin">
      <h3>Floatin – the pinterest of NFTs</h3>
      <p>
        I've designed and developed a web3 application in which users can search
        for on-chain artworks, and create their own collections.
      </p>
      <figure class="nomargintop">
        <img
          class="invertedColour"
          src="@/assets/images/Solopreneur/floatin/coding.png"
          alt=""
        />
        <figcaption>Programming screenshot</figcaption>
      </figure>

      <figure class="nomarginbottom">
        <img
          class="invertedColour noborderradius"
          src="@/assets/images/Solopreneur/floatin/desktop1.png"
          alt=""
        />
        <figcaption></figcaption>
      </figure>
      <div class="floatinRow row spaceBetween nomargintopbottom">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/floatin/mobile1.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/floatin/mobile2.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/floatin/mobile3.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
      </div>
    </div>
    <hr />
    <div class="bNC">
      <h3>borNauthentiC – fashion brand and e-commerce website</h3>
      <p>
        Born Authentic tasked me and designer Albino Tavares with undertaking a
        comprehensive rebranding and creating an e-commerce platform. For the
        client, maintaining the brand name, Born + Authentic, was crucial. These
        words brought forth themes of "passage of time", "growth", and
        "uniqueness". Our design approach was to symbolically represent these
        ideas by combining lowercase and uppercase letters within the brand
        name. We crafted a distinctive expression: borNauthentiC. This
        unconventional capitalisation method symbolises the journey of growth,
        reflecting the concept of starting small and gradually growing over
        time.
      </p>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/Solopreneur/bNC/logo-long-whitebg.jpg"
          alt=""
        />
        <figcaption></figcaption>
      </figure>
      <figure class="nomarginbottom">
        <img
          class="invertedColour"
          src="@/assets/images/Solopreneur/bNC/logo-long-blackbg.jpg"
          alt=""
        />
        <figcaption></figcaption>
      </figure>
      <figure class="nomarginbottom">
        <img
          class="invertedColour"
          src="@/assets/images/Solopreneur/bNC/logo-short-2.jpg"
          alt=""
        />
        <figcaption></figcaption>
      </figure>
      <div class="row spaceBetween nomargintop">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/bNC/fashion_1.jpeg"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/bNC/fashion_2.jpeg"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/bNC/fashion_3.jpeg"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
      </div>
      <figure class="nomargintop">
        <img
          class="invertedColour"
          src="@/assets/images/Solopreneur/bNC/fashion_4.jpeg"
          alt=""
        />
        <figcaption></figcaption>
      </figure>
      <figure class="nomargintopbottom">
        <img
          class="invertedColour noborderradius"
          src="@/assets/images/Solopreneur/bNC/desktop1.png"
          alt=""
        />
        <figcaption></figcaption>
      </figure>
      <div class="row spaceBetween nomargintop">
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/bNC/mobile1.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/bNC/mobile2.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/bNC/mobile3.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
        <figure>
          <img
            class="invertedColour"
            src="@/assets/images/Solopreneur/bNC/mobile4.png"
            alt=""
          />
          <figcaption></figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],
};
</script>

<style scoped>
.row {
  align-items: flex-start;
  gap: var(--smallMargin);
}
/* Add styles specific to this component */
</style>
