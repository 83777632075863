<template>
  <div id="app">
    <!-- <transition name="slide-out">
      <PasswordPrompt
        v-if="!passwordEntered"
        @passwordCheck="onPasswordCheck"
      />
    </transition> -->
    <div class="nav-background" :class="{ 'hide-nav-bg': !showNav }"></div>

    <div
      class="navigation"
      :class="{ 'hide-nav': !showNav, 'show-nav': showNav }"
    >
      <nav>
        <router-link to="/">João</router-link>
        <router-link to="/services">Services</router-link>
        <router-link to="/insights">Texts & publications</router-link>
        <router-link to="/about">About</router-link>
      </nav>
    </div>
    <div class="mainCont">
      <WideScreenLayout v-if="isWideScreen" :is-wide-screen="isWideScreen" />
      <div
        v-else
        class="default-screen-layout"
        :class="{ 'browser-bar-visible': browserBarVisible }"
        :is-wide-screen="isWideScreen"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import PasswordPrompt from "@/components/LandingComponents/PasswordPrompt.vue";
import WideScreenLayout from "@/views/WideScreenLayout.vue";

export default {
  components: {
    PasswordPrompt,
    WideScreenLayout,
  },
  setup() {
    const store = useStore();
    const passwordEntered = ref(false);
    const localShowNav = ref(true); // Renamed to avoid confusion
    let lastScrollPosition = 0;
    const browserBarVisible = ref(false);
    const showNav = computed(
      () => localShowNav.value && store.state.isNavVisible
    );

    const checkStoredPassword = () => {
      const validUntil = localStorage.getItem("passwordValidUntil");
      if (validUntil && new Date().getTime() < validUntil) {
        passwordEntered.value = true;
      }
    };

    function toggleNav() {
      store.dispatch("toggleVersion");
    }

    function onPasswordCheck(isPasswordCorrect) {
      if (isPasswordCorrect) {
        const now = new Date();
        localStorage.setItem(
          "passwordValidUntil",
          now.getTime() + 60 * 60 * 1000
        ); // 1 hour from now

        passwordEntered.value = true;
      }
      // No else part needed as the error handling is done in the PasswordPrompt component
    }

    const handleScroll = () => {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition <= 5) {
        localShowNav.value = true; // Show nav when at the top of the page
      } else {
        localShowNav.value = currentScrollPosition < lastScrollPosition; // Hide nav when scrolling down
      }
      store.commit("setNavVisibility", localShowNav.value); // Update Vuex state

      lastScrollPosition = currentScrollPosition;
    };

    const isWideScreen = ref(window.innerWidth > 1400);

    // Watch for changes in the window width and update isWideScreen accordingly
    const updateWidth = () => {
      isWideScreen.value = window.innerWidth > 1400;
    };

    // check if browser bar is visible on top (for android devices)
    const checkBrowserBar = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      const windowHeight = window.outerHeight;
      const viewportHeight = window.innerHeight;
      browserBarVisible.value = windowHeight > viewportHeight;
    };

    onMounted(() => {
      checkStoredPassword();
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateWidth);
      checkBrowserBar();
      window.addEventListener("resize", checkBrowserBar);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateWidth);
      window.removeEventListener("resize", checkBrowserBar);
    });

    return {
      passwordEntered,
      onPasswordCheck,
      showNav,
      isWideScreen,
      browserBarVisible,
    };
  },
};
</script>

<style>
.mainCont {
  /* padding-top: calc(
    var(--smallClickHeight) + var(--smallMargin) + var(--regularMargin)
  ); */
  margin-top: 0 !important;
}

.navigation {
  position: fixed;
  top: var(--smallMargin);
  display: flex;
  gap: var(--regularMargin);
  justify-content: space-between;
  min-height: var(--smallClickHeight);
  align-items: center;
  z-index: 2;
}

nav a {
  display: flex;
  text-decoration: none;
  color: var(--foregroundD);
}

.nav-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--smallClickHeight) + 2 * var(--regularMargin));
  background: linear-gradient(to bottom, var(--backgroundD) 30%, transparent);
  z-index: 2;
  transition: transform 0.3s ease;
  transform: translateY(0);
}

.hide-nav-bg {
  transform: translateY(-100%);
}

/* .navImg {
  height: var(--smallClickHeight);
} */

.hide-nav {
  transform: translateY(-150%);
  transition: transform 0.3s ease;
}

.show-nav {
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.router-link-active {
  color: var(--primary);
  font-weight: bold;
}

nav img {
  filter: grayscale(100%);
}
.router-link-active img {
  filter: grayscale(0%);
}

.default-screen-layout {
  padding-top: var(--smallClickHeight);
  min-height: calc(100vh - var(--smallClickHeight));
}

/* WIDE SCREENS */
@media (min-width: 1400px) {
  body {
    margin: 0px !important;
  }
  .navigation {
    display: none;
  }

  .nav-background {
    display: none; /* This hides the navigation background */
  }

  .mainCont {
    display: flex; /* Displays child views in columns */
    flex-wrap: nowrap; /* Prevents wrapping to a new line */
    overflow-x: auto; /* Allows horizontal scrolling */
    margin-top: 0;
  }

  .view {
    min-width: 100%; /* Each view takes at least the full width of the screen */
    max-width: 100%; /* Ensures the view does not exceed screen width */
    /* Define any other styles for your views here */
  }
}
</style>
