<template>
  <div>
    <transition name="slide">
      <div v-show="isVisible" class="drawer" @click.stop ref="drawerContent">
        <!-- <button @click="closeDrawer">Close</button> -->
        <div class="">
          <h3 class="nomargintopbottom">{{ selectedItem }}</h3>
          <svg @click="closeDrawer" class="compactImg cross">
            <use href="@/assets/icons/iconset.svg#compactCross"></use>
          </svg>
        </div>
        <div class="drawerContent" ref="drawerContent">
          <slot></slot>
        </div>
        <!-- Content and close button -->
      </div>
    </transition>
    <!-- Overlay should only be present when the drawer is visible -->
    <div v-show="isVisible" class="overlay" @click="closeDrawer"></div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from "vue";

export default {
  props: {
    isVisible: Boolean,
    selectedItem: String,
  },
  emits: ["update:isVisible"],
  setup(props, { emit }) {
    const drawerContent = ref(null);

    const closeDrawer = () => {
      emit("update:isVisible", false);
    };

    watch(
      () => props.selectedItem,
      () => {
        if (drawerContent.value) {
          nextTick(() => {
            drawerContent.value.scrollTop = 0; // Resets the scroll position to the top
          });
        }
      }
    );

    watch(
      () => props.isVisible,
      (newValue) => {
        const body = document.querySelector("body");
        if (newValue) {
          body.classList.add("no-scroll");
        } else {
          body.classList.remove("no-scroll");
        }
      }
    );

    return { closeDrawer, drawerContent };
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dimmed background */
  z-index: 999;
  backdrop-filter: blur(10px);
}
.drawer {
  width: calc(700px + 2 * var(--regularMargin));
  min-width: 50%;
  height: 100vh;
  overflow-y: auto; /* Allow vertical scrolling inside the drawer */

  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--backgroundD_lighter);
  /* Initially offscreen, handled by Vue transitions */
  z-index: 1000; /* Make sure it's above other content */
  padding: var(--regularMargin);
  padding-bottom: calc(2 * var(--largerMargin));
}
.drawer .navigation {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.drawerContent {
  margin-top: var(--regularMargin);
}

.cross {
  background-color: var(--backgroundD_lighter_transp);
  z-index: 2;
  position: fixed;
  right: var(--largerMargin);
  top: var(--regularMargin);
  cursor: pointer;
  border-radius: var(--smallerMargin);
  box-shadow: inset -1px -2px 5px var(--backlightLow),
    inset 2px 2px 8px rgb(255, 212, 212), 0px 2px 8px var(--backgroundD_darker);
  background-color: var(--primary);
}

/* Define enter and leave transitions */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
.slide-enter-to,
.slide-leave-from {
  transform: translateX(0%);
}
li {
  text-indent: calc(-1 * var(--regularMargin));
  padding-left: var(--regularMargin);
  display: list-item;
  margin-bottom: var(--smallerMargin);
}

/* SMALL DEVICES */

@media screen and (max-width: 768px) {
  .cross {
    right: var(--regularMargin);
  }
  .drawer {
    width: 88%;
  }
}
</style>
