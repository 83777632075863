<template>
  <div>
    <div class="quote">
      <h3 class="smallmarginbottom"></h3>
    </div>
    <h3></h3>
    <h3 class="">What is service design?</h3>
    <p>
      Service design is a relatively new discipline focused on enhancing user
      experiences. I employ many of its methodlogies in my projects, especially
      when working with large organisations.
    </p>
    <p>
      As a Service Designer, I support organisations in their transformative
      journey, merging design with technology from a human and user-centric
      perspective.
    </p>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/EY/workshop_1.jpg"
        alt="Two day workshop with the client"
      />
      <figcaption>
        A service design engagement can range from a brief two-week project with
        the client, addressing specific needs, to extended year-long projects,
        such as the one in the photo above of a two-day hackathon within a
        year-long commitment to create a new service from scratch for a large
        international financial institution.
      </figcaption>
    </figure>

    <!-- <p class="smallmarginbottom">
      <small> Industries:</small>
    </p>
    <div class="inlineBlock">
      <div class="tag">Finance</div>
      <div class="tag">Wealth management</div>
      <div class="tag">Banking</div>
      <div class="tag">Renewable energies</div>
      <div class="tag">Retail analytics</div>
      <div class="tag">Genomics</div>
      <div class="tag">Education</div>
      <div class="tag">Blockchain</div>
    </div> -->

    <div class="notification alert">
      <p class="nomargintopbottom">
        The design materials displayed below are intentionally modified or
        presented in low resolution to safeguard client confidentiality and
        competitive advantage.
      </p>
    </div>
    <p>
      Service design work often revolves around a service blueprint that links
      user interactions to the operational work behind the scenes. Depending on
      the team's size and the project's scope, my work may involve both hands-on
      tasks and strategic contributions.
    </p>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/EY/client_proto.png"
        alt="Low fidelity prototyping and service blueprint"
      />
      <figcaption>Prototype (above) and service blueprint (below)</figcaption>
    </figure>

    <!-- <figure>
      <img
        class="invertedColour"
        src="@/assets/images/EY/client_proto_2.png"
        alt=">High fidelity prototyping of desktop application"
      />
      <figcaption>High fidelity prototyping of desktop application</figcaption>
    </figure> -->
    <!-- <figure>
      <img
        class="invertedColour"
        src="@/assets/images/EY/client_proto_6.png"
        alt=">High fidelity prototyping of desktop application"
      />
      <figcaption>High fidelity prototyping of desktop application</figcaption>
    </figure> -->

    <!-- <div class="row spaceBetween">
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/EY/client_proto_3.png"
          alt="High fidelity prototyping of mobile application"
        />
        <figcaption>Mobile examples</figcaption>
      </figure>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/EY/client_proto_4.png"
          alt="High fidelity prototyping of mobile application"
        />
        <figcaption></figcaption>
      </figure>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/EY/client_proto_5.png"
          alt="High fidelity prototyping of mobile application"
        />
        <figcaption></figcaption>
      </figure>
    </div> -->

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/EY/workshops.jpg"
        alt="Example of the outcomes of a series of Definition workshops with a
        council"
      />
      <figcaption>
        Example of the outcomes of a series of Definition workshops with a
        council
      </figcaption>
    </figure>

    <hr />
    <h3 class="smallmarginbottom">Roles & responsibilites</h3>
    <p>
      I've worked as a service designer in both business transformation and
      innovation projects.
    </p>

    <ul>
      <li>
        <strong>Project management:</strong> Liaise with senior business
        stakeholders and managers, playing a crucial role in various phases of
        project development.
      </li>
      <li>
        <strong>Evaluating and prioritising tasks:</strong> Analysing and
        determining the importance and urgency of tasks to optimise workflow and
        resource deployment.
      </li>
      <li>
        <strong>Work in uncertain environments:</strong>
        navigating ambiguity to conceptualise operating models and realise
        innovative design solutions, effectively translating abstract ideas into
        tangible artefacts to help visualise, conceptualise, and improve
        organisational processes.
      </li>
      <li>
        <strong>Advocating for the end-user:</strong> designing end-to-end
        service blueprints validated by in-depth sessions with key stakeholders.
        Crafting user journeys, screens, and other stimuli needed to adequately
        design the service.
      </li>
      <li>
        <strong>Design implementation:</strong> Using my programming know-how, I
        troubleshoot potential technical and service challenges within business
        strategies. I liaise with solution architects and developers to ensure
        the service is implemented as intended.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["content"],
};
</script>

<style scoped>
.row {
  align-items: flex-start;
  gap: var(--smallerMargin);
}

li {
  text-indent: calc(-1 * var(--regularMargin));
  padding-left: var(--regularMargin);
  display: list-item;
  margin-bottom: var(--smallMargin);
}
</style>
