<template>
  <div class="password-prompt">
    <transition name="slide-out">
      <p v-if="passwordError" class="error-message">Password is incorrect</p>
    </transition>
    <div class="column alignCentre">
      <img src="/img/icons/android-chrome-512x512.png" />
      <p class="alignCentre nomarginbottom">João Marrucho's website</p>

      <form @submit.prevent="checkPassword" class="alignCentre">
        <div class="input-group">
          <input
            v-model="inputPassword"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Type password"
            class="alignCentre passwordInput"
          />
          <button
            type="button"
            class="toggle-password"
            @click="togglePasswordVisibility"
          >
            <svg v-if="showPassword" class="svgIcon">
              <use href="@/assets/icons/iconset.svg#hideDark"></use>
            </svg>
            <svg v-else class="svgIcon">
              <use href="@/assets/icons/iconset.svg#viewDark"></use>
            </svg>
          </button>
        </div>
        <button type="submit">Enter</button>
      </form>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  setup(props, { emit }) {
    const inputPassword = ref("");
    const passwordError = ref(false);
    const showPassword = ref(false);

    const checkPassword = () => {
      if (inputPassword.value === "welcome") {
        emit("passwordCheck", true);
        passwordError.value = false;
      } else {
        passwordError.value = true;
        setTimeout(() => {
          passwordError.value = false;
        }, 8000); // 8 seconds
      }
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      inputPassword,
      checkPassword,
      passwordError,
      showPassword,
      togglePasswordVisibility,
    };
  },
};
</script>

<style scoped>
.password-prompt {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: var(--backgroundD);
}
.password-prompt .column {
  height: 100%;
  gap: var(--regularMargin);
}

.password-prompt img {
  width: 90px;
  height: auto;
  filter: drop-shadow(0px 0px 25px rgba(255, 112, 245, 0.471));
}

.error-message {
  text-align: center;
  position: absolute;
  color: red;
  margin-top: 10px;

  width: 100%;
}

.error-message {
  text-align: center;
  color: red;
  margin-top: 10px;
  /* Other styles as needed */
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.toggle-password {
  display: flex;
  position: absolute;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  box-shadow: none;
  margin: 0;
}

.passwordInput {
  width: 250px;
}
</style>
