<template>
  <div class="insights">
    <h2 v-if="isWideScreen">Texts & publications</h2>
    <MediumFeed />
    <br />
    <hr />
    <br />
    <PrintPublications />
  </div>
</template>

<script>
import MediumFeed from "@/components/InsightsComponents/MediumFeed.vue";
import PrintPublications from "@/components/InsightsComponents/PrintPublications.vue";

export default {
  name: "Insights",
  components: {
    MediumFeed,
    PrintPublications,
  },
  props: {
    isWideScreen: Boolean,
  },
};
</script>

<style scoped></style>
