<template>
  <div class="wide-screen-layout">
    <HomeView class="column" :is-wide-screen="isWideScreen" />
    <ServicesView class="column" :is-wide-screen="isWideScreen" />
    <InsightsView class="column" :is-wide-screen="isWideScreen" />
    <AboutView class="column" :is-wide-screen="isWideScreen" />
  </div>
</template>

<script>
import HomeView from "@/views/HomeView.vue";
import ServicesView from "@/views/ServicesView.vue";
import InsightsView from "@/views/InsightsView.vue";
import AboutView from "@/views/AboutView.vue";

export default {
  name: "WideScreenLayout",
  components: {
    HomeView,
    ServicesView,
    InsightsView,
    AboutView,
  },
  props: {
    isWideScreen: Boolean,
  },
};
</script>

<style>
.wide-screen-layout {
  display: flex;
  height: 100vh; /* Adjust if necessary */
  overflow-y: auto; /* Scroll vertically */
}

.column {
  flex: 1; /* Each takes equal width */
  width: 25%; /* Or use flex-basis: 25%; if you prefer */
  overflow-y: auto; /* Each column scrolls independently */
  overflow-x: clip;
  padding: 0 var(--smallMargin);
  padding-left: calc(
    var(--smallMargin) + 3px
  ); /* Add extra padding on the left */
  margin-left: 3px; /* Negative margin to shift content to the left */
  box-sizing: border-box;
  border-left: 1px solid var(--foregroundD_darker);
}
.column::-webkit-scrollbar {
  background-color: var(--backgroundD);
  width: var(--smallestMargin);
  transform: translateY(3px);
}
.column::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}
.services.column {
  padding: 0 0 0 0;
}

.home.column {
  border-left: none;
  justify-content: space-between;
}
</style>
