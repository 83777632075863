<template>
  <div class="home">
    <div>
      <h2 :class="{ nomarginbottom: isWideScreen }" v-if="isWideScreen">
        Hello!
      </h2>

      <div ref="pageContainer readingWidth" class="page-container">
        <HelloWorld :is-wide-screen="isWideScreen" />
      </div>
    </div>
    <LogoSlider />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from "vue";
import HelloWorld from "@/components/HelloWorld.vue";
import LogoSlider from "@/components/GenericComponents/LogoSlider.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    LogoSlider,
  },
  props: {
    isWideScreen: Boolean,
  },
  setup() {
    const pageContainer = ref(null);

    return {
      pageContainer,
    };
  },
};
</script>

<style scoped>
/* .page-container .intro {
  width: 100%;
  min-height: calc(
    100vh -
      calc(var(--smallClickHeight) + var(--smallMargin) + var(--regularMargin))
  );
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */
</style>
