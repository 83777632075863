<template>
  <div>
    <!-- <div class="quote">
      <h3 class="smallmarginbottom">
        "Give me 20 million euros and I'll solve the world's money problems. If
        I fail, give me 20 more so I can solve mine."
      </h3>
    </div>-->
    <hr />
    <p>
      Unit is a social contract and monetary ecosystem developed over years to
      offer an inclusive, flexible way to exchange value. With its unique
      exchange rate where currency is indexed to human life (coins per capita at
      the time of trade), any organisation can create its own coin and trade
      with other organisations using other coins.
    </p>
    <hr />
    <p>
      Unit's journey begins with the 2008 financial crisis. Austerity measures
      left a deep scar on Portugal’s economy. In Bonfim, one of Porto's priciest
      areas where one square meter of real estate costs €3150 (Sep, 2023),
      values plummeted to €500 (Mar, 2012). At the time, I was a junior designer
      struggling to survive on low salaries. The belief that design can
      fundamentally reshape the way we think about things, and that money is a
      cultural construct, drove me to dive deeper into economic issues.
    </p>

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/units_first_sketch.png"
        alt="Unit's first sketch, 2008"
      />
      <figcaption>Unit's first sketch, 2008</figcaption>
    </figure>

    <p>
      I took courses in management, accounting, and delved into political
      economy and monetary theory. Each article, paper, book and online video
      inspired me to write and engage in discussions, further refining my
      approach. My initial dissatisfaction and curiosity ultimately led to a new
      monetary model, which I initially named
      <span class="link"
        ><a
          target="_blank"
          href="https://www.researchgate.net/publication/271847545_Modelo_Monetario_Cilvil"
        >
          'Civil Monetary Model' (Nov, 2012)<svg class="microSvg">
            <use
              href="@/assets/icons/iconset.svg#microExternalLink"
            ></use></svg></a></span
      >.
    </p>
    <p>
      At that stage, my concept was limited to a single Coin and a social
      contract based on my own personal political inclination. For that reason I
      attempted to prescribe
      <strong>too many rules</strong>. The first draft was overly detailed and
      rigid. It had:
    </p>
    <ul>
      <li>Excessive emphasis on wages and productivity indexes</li>
      <li>Mandatory UBI</li>
      <li>Predefined coin lifespan (6 months to spend)</li>
    </ul>

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/diagram1.png"
        alt="Testing solutions through speculative economic scenarios (Civil Monetary Model)"
      />
      <figcaption>
        Civil Monetary Model: testing solutions through speculative economic
        scenarios
      </figcaption>
    </figure>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/diagram2.png"
        alt="Initial study to test Unit's monetary instruments (Civil Monetary Model)"
      />
      <figcaption>
        Civil Monetary Model: initial study to test Unit's monetary instruments
      </figcaption>
    </figure>
    <p>
      When Bitcoin rose to prominence, many assumed I was working on a
      cryptocurrency. However, by definition, blockchains are disconnected from
      the social contracts proposed by traditional forms of governance. Even
      though some blockchain projects provided the possibility of creating
      smart-contracts, it was still hard to imagine a monetary model working
      within a fully decentralised architecture.
    </p>
    <h2 class="nomarginbottom">Master's study at RCA</h2>
    <p>
      Feeling increasingly alienated post-Brexit, I took a Master's in Service
      Design at the Royal College of Art. At RCA, I continued using
      <span
        ><a target="_blank" href="https://miro.com/app/board/o9J_la6PMvU=/"
          >collaborative methodologies<svg class="microSvg">
            <use
              href="@/assets/icons/iconset.svg#microExternalLink"
            ></use></svg></a></span
      >, refining the monetary model, now rebranded as “Unit”, a sci-fi trope
      for futuristic currencies.
    </p>
    <p class="smallmargintop">
      The project evolved to explore money as a tool to enact different social
      contracts, not just the one I had in mind.
    </p>

    <p>
      Everyone thinks slightly differently about money –there are familial,
      societal, cultural, geographical, etc. nuances– so instead of designing a
      single monetary system, my research led me to a
      <strong>monetary ecosystem</strong> that allows people to create their own
      monetary systems. Like this, the project could accommodate both novel and
      traditional organisational styles. In this venture, this monetary model
      had become more than a project; it was a journey through the intricacies
      of deep design, the deconstruction of entrenched mental models, and
      pursuing meaningful social innovation.
    </p>

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/unitsresearchsite.png"
        alt="Unit's research website: simulating scenarios"
      />

      <figcaption>
        MA research: simulating scenarios, 2020 |

        <a target="_blank" href="https://unit-world.web.app/"
          ><span class="link"
            >View built website<svg class="microSvg">
              <use
                href="@/assets/icons/iconset.svg#microExternalLink"
              ></use></svg></span
        ></a>
      </figcaption>
    </figure>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/units_wallet_prototype_2.png"
        alt="Unit's wallet prototype"
      />

      <figcaption>
        Unit's wallet prototype, 2021 |
        <a
          target="_blank"
          href="https://www.figma.com/proto/alDvXryXb4wq62QjHxVl2X/UNIT-UI-(neomorphism-1.5)?node-id=249-129&starting-point-node-id=249%3A129&scaling=scale-down"
          ><span class="link"
            >View prototype<svg class="microSvg">
              <use
                href="@/assets/icons/iconset.svg#microExternalLink"
              ></use></svg></span
        ></a>
      </figcaption>
    </figure>
    <p>
      In my Master's studies, I dedicated my thesis to addressing the tensions
      between workers and business owners, and between government and private
      business interests. This focus, however, made it challenging to confront
      the prevalent belief that these groups alone drive societal value. Many
      argue that societal value is also created in unrecognised ways. For
      instance, a single parent nurturing their children or an adult caring for
      elderly parents can contribute immense value to society. This form of
      contribution, often overlooked, is not quantifiable as labour or capital
      and is not provided by public or private entities.
    </p>

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/units_lifecycle.jpg"
        alt="Unit's currency lifecycle: monetary tools summary, 2020"
      />
      <figcaption>
        Unit's currency lifecycle: monetary tools summary, 2019
      </figcaption>
    </figure>
    <p>
      The current trend in Europe reveals a fracturing of established social
      structures. The growth of Western economies has led to an increased wealth
      gap. Additionally, a rise in right-wing movements signals a new phase of
      societal unrest. Technological advancements, particularly in artificial
      intelligence, highlight the need for a critical reassessment of our
      values.
    </p>
    <!-- <p>
      Regarding the upcoming EY insight, it's important to approach it with a
      degree of skepticism. The report tends to overgeneralise young people's
      behavior, describing them as idealistic and impulsive, challenging social
      norms while trying to fit into groups. However, such stereotypes overlook
      the complexity of forming effective social contracts that genuinely
      resonate with the diverse experiences and perspectives of the youth. As we
      work towards more inclusive and adaptable monetary ecosystems, these
      nuanced understandings become crucial.
    </p> -->
    <!--  <h3 class="quote smallmarginbottom">
      "The mass introduction of artificial intelligence (AI) into (Gen Z) daily
      work and personal lives may create higher barriers to trust for this
      skeptical generation."
    </h3>
     <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/Ey_study_1.png"
        alt="Chart from 2023 EY Gen Z Segmentation Study"
      />
      <figcaption>
        Chart from
        <a
          target="_blank"
          href="https://assets.ey.com/content/dam/ey-sites/ey-com/en_us/topics/consulting/ey-2307-4309403-genz-segmentation-report-us-score-no-20902-231us-2-vf4.pdf"
          >2023 EY Gen Z Segmentation Study
          <svg class="microSvg">
            <use href="@/assets/icons/iconset.svg#microExternalLink"></use></svg
        ></a>
      </figcaption>
    </figure>

    <h3 class="quote smallmarginbottom">
      "67% of Gen Z believe most people can’t be trusted. (...) If established
      systems aren’t working for them, Gen Z finds ways around it. (...) They
      crave authenticity and demand transparency, but don’t think most companies
      or governments are doing a great job of giving it to them"
    </h3> -->

    <h2 class="nomarginbottom">Platform design</h2>
    <p>As time passed it became clear Unit needed more open-ended approach.</p>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/units_lifecycle_2.png"
        alt="Unit's currency lifecycle: monetary tools summary, 2023"
      />
      <figcaption>
        Unit's currency lifecycle 4 years later: Unit became sector-agnostic (no
        distinction between public and private sectors) with optional adjustable
        monetary features, 2023
      </figcaption>
    </figure>

    <p>
      To materialise this new approach, during 2023 I've developed an
      <span class="link"
        ><a target="_blank" href="https://unit-org.web.app/">
          online platform
          <svg class="microSvg">
            <use
              href="@/assets/icons/iconset.svg#microExternalLink"
            ></use></svg></a></span
      >. online platform in which users can create their organisations, register
      members, set roles, designate their own currencies, and select from a
      comprehensive array of monetary tools, with the ability to modify values
      as needed.
    </p>

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/desktop_1.png"
        alt="Creating a unique currency within Unit's webapp, 2023"
      />
      <figcaption>
        Unit's platform, 2023 |

        <a target="_blank" href="https://unit-org.web.app/"
          ><span class="link"
            >View built platform<svg class="microSvg">
              <use
                href="@/assets/icons/iconset.svg#microExternalLink"
              ></use></svg></span
        ></a>
      </figcaption>
    </figure>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/tablet_1.png"
        alt="Creating a unique currency within Unit's webapp, 2023"
      />
      <figcaption>Unit's platform, 2023</figcaption>
    </figure>
    <div class="rowOnDesktop spaceBetween">
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/Unit/Unit_dev_phone_1.png"
          alt="Creating a unique currency within Unit's webapp, 2023"
        />
        <figcaption>Creating a unique currency within Unit's webapp</figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/Unit/Unit_dev_phone_2.png"
          alt="Creating an organisation within Unit's webapp, 2023"
        />
        <figcaption>Creating an organisation within Unit's webapp</figcaption>
      </figure>
    </div>
    <div class="row spaceBetween">
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/Unit/Unit_dev_phone_5.png"
          alt="Chosing and adjusting monetary parameters within an organisation, 2023"
        />
        <figcaption>
          Adjusting monetary parameters within an organisation
        </figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/Unit/Unit_dev_phone_4.png"
          alt="Creating a role within an organisation, 2023"
        />
        <figcaption>Creating a role within an organisation</figcaption>
      </figure>
    </div>
    <div class="row spaceBetween">
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/Unit/Unit_dev_phone_6.png"
          alt="Checking the wallet, 2023"
        />
        <figcaption>Checking the wallet</figcaption>
      </figure>
      <figure>
        <img
          class="small invertedColour"
          src="@/assets/images/Unit/Unit_dev_phone_7.png"
          alt="Creating a product to sell on the marketplace, 2023"
        />
        <figcaption>Adding product to marketplace</figcaption>
      </figure>
    </div>

    <h2 class="nomarginbottom">Finding use cases</h2>

    <p>
      Organisations aren’t just made through technology, input fields and online
      forms so I’ve relocated to my hometown to collaborate with the council to
      understand the socioeconomic dynamics of a small town and assess if they
      would benefit from using Unit. I’ve run initial social contract workshops,
      with the council, to map existing local stakeholders, their needs, the
      value they create, and see if we would find hidden matches between supply
      and demand of value.
    </p>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/cmf_workshop.png"
        alt="Workshops' outputs documented in a collaborative board created with key stakeholders,
        2023"
      />
      <figcaption>
        A collaborative board created with key stakeholders, 2023
      </figcaption>
    </figure>
    <p>
      The initial set of meetings and workshops with the council led me to a
      very special grocery store "Project Matrix" (translated) ran by Gina, with
      whom I've completed a short cycle of work. They already have a local
      currency in place with its own social contract.
    </p>
    <p>
      It’s a small system where people bring surplus groceries and trade them
      for others. If they don’t find anything they need, they are given physical
      coins they can use next time. For example: a local farmer brings a bag of
      beans and takes home milk. The contract extends actively. The cleaner is
      also paid with this coin. A local baker uses the council's public oven and
      and supplies 10 loaves of bread to the grocery shop (she sells the rest to
      businesses and individuals). So if the farmer prefers, there’s always
      freshly baked bread and other produce. Children from diverse, often
      vulnerable, backgrounds gather here at the shop. In the adjacent room,
      they drop their phones in a basket and play with each other, learn new
      skills and help with the grocery shop chores, help the baker deliver
      bread, and engage in all sorts of other activities, supervised by
      well-trained professionals.
    </p>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/cmf_matriz.jpeg"
        alt="Flávio (supervisor), João, and João behind the counter, 2023"
      />
      <figcaption>
        Flávio (supervisor), João, and João behind the counter, 2023
      </figcaption>
    </figure>
    <p>
      Two inquisitive children, both named João and in the 7th and 9th grades,
      explained the whole system to me. I asked them if they thought a digital
      payment system, where people order from home and pay using their phones,
      would be valuable to them. They explained:
    </p>
    <h3 class="quote">
      "If we would use a digital coin we wouldn’t meet as many people. Moreover,
      if people could pay and reserve a product online it would be harder to
      keep track of the stock. We can simply let more people know about this
      grocery."
    </h3>
    <p>
      These 13 year olds immediately highlighted that an extra digital channel
      would likely be adding extra workload for a loss of face-to-face
      interaction.
    </p>
    <p>
      I visited another shop called "Loja Social": A community-focused social
      shop offering essential goods like food, clothing, and furniture, and
      providing specialised social services, all facilitated through public
      participation and collaboration. I've interviewed the shop's supervisor,
      and ran a follow-up session with the head of the Council's Social Action
      area.
    </p>

    <!-- <div class="row spaceBetween">
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/Unit/Loja_social_porta.jpg"
          alt="Checking the wallet, 2023"
        />
        <figcaption>The door of Loja Social</figcaption>
      </figure>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/Unit/Loja_social_ficha.jpg"
          alt="An user application form"
        />
        <figcaption>An user application form</figcaption>
      </figure>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/Unit/Loja_social_arq.jpg"
          alt="Documentation archives"
        />
        <figcaption>Documentation archives, 2023</figcaption>
      </figure>
    </div> -->
    <div class="row spaceBetween">
      <figure>
        <img
          class="smaller invertedColour"
          src="@/assets/images/Unit/Loja_social_porta.jpg"
          alt="Checking the wallet, 2023"
        />
        <figcaption>The entry of Loja Social</figcaption>
      </figure>
      <figure>
        <img
          class="invertedColour"
          src="@/assets/images/Unit/Loja_social_games&toys.jpg"
          alt="Toys and games displays at the social shop"
        />
        <figcaption>
          Well-curated, toys and games offering options for different ages and
          interests at the social shop
        </figcaption>
      </figure>
    </div>

    <p>
      The shop is managed by the Council's Social Action team in partnership
      with a supermarket chain, two companies, and a university startup
      incubator. They assist a local textile company in managing its excess
      fabrics to reduce environmental impact. These fabrics are processed by the
      startup from the Textile Engineering Department of the University of
      Minho, where designers create new clothing items, some of which are
      donated back to the shop, and others are sold on their marketplace app.
      Additionally, a recycling company operates public clothing donation points
      and pays the council an annual fee for managing these collections. The
      council uses the proceeds to purchase food from the supermarket, which
      also supplies the shop with edible, slightly outdated food items for
      distribution.
    </p>
    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/Socio_econ_interactions.jpg"
        alt="Flávio (the supervisor), João, and João, explaining why they prefer a
        physical coin, 2023"
      />
      <figcaption>
        Socioeconomic interactions between Loja Social and Projecto Matriz
        stakeholders, 2023.
      </figcaption>
    </figure>

    <p>
      Follow up sessions with the the head of Social action, Loja Social team,
      and Projecto Matriz, will be used to assess service pain points and
      opportunities.<br /><br />
    </p>
    <p>
      <strong>So far we've realised the implementation of Unit coins...</strong>
    </p>
    <ul>
      <li>
        should not force the use of smartphones by children in Projecto Matriz
      </li>
      <li>could be extended to the council's Loja Social</li>
      <li>could be useful in events such as the local monthly street market</li>
      <li>could be used to reward extra volunteers at the grocery shop</li>
    </ul>

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/rca_session.jpg"
        alt="Screenshot of session with RCA Service Design MA students"
      />
      <figcaption>
        Session with RCA Service Design MA students 19<sup>th</sup> Dec 2023 |
        Alomi Parikh, Product Designer, India 🇮🇳; Douwe Kruyt, Research
        Deloitte, Holland 🇳🇱; Hiroyuki Okuda, Management Consultant Deloitte,
        Japan 🇯🇵; Marco Pizarro, Project Manager, Peru 🇵🇪; Shuhei Okubo,
        Designer Ministry Internal Affair & Communication, Japan 🇯🇵; Xiaoyue
        Lian, UX Designer, China 🇨🇳
      </figcaption>
    </figure>
    <p>
      Parallel to this, a dynamic session was conducted with Service Design MA
      candidates at the Royal College of Art. The purpose was to conceptually
      test Unit's current shape, to gather critiques from the next generation of
      service designers about this concept of a versatile monetary ecosystem.
      The students asked difficult questions and were active participants,
      exploring a wide array of potential use cases for Unit. The students
      proposed creating various applications linked by 'Unit's exchange rate,
      showcasing the platform's flexibility.
    </p>

    <p>
      They conceived scenarios ranging from a SaaS platform for enhancing team
      collaboration by tracking contributions both quantitatively and
      qualitatively, drawing insights from "The Mythical Man-Month", to using
      Unit as a catalyst for public decision-making processes, thereby
      increasing community engagement in the public sector. They envisioned Unit
      providing a fun element for clubs, societies, or school groups to
      establish self-autonomy systems, as well as a framework for small
      neighborhood tool borrowing schemes. The session also touched upon the
      importance of financial education, discussing how 'Unit' could serve as a
      tool for enhancing financial literacy.
    </p>
    <p>
      This collaborative session highlighted the innovative potential of 'Unit'
      to accommodate both traditional and novel organisational styles, bridging
      the gap between theoretical economic models and practical, user-centered
      applications.
    </p>

    <h2 class="nomarginbottom">Architecture</h2>
    <p>
      Currently, the broader tech architecture is leaning towards a hybrid
      approach, with a centralised database and a decentralised blockchain
      smart-contract for exchange rates. The centralised database allows for
      fast and flexible development, and the blockchain smart-contract provides
      a secure and transparent exchange rate.
    </p>
    <p>
      The platform is built with Vue.js, Firebase, and Firestore. The
      blockchain's smart-contract is written in Solidity and deployed on the
      Ethereum blockchain.
    </p>
    <p>
      As the project grows it will be strategic to create an easy way to onboard
      App registration process, as well implenting trustworthy controls for
      secure data validation for those apps.
    </p>

    <figure>
      <img
        class="invertedColour"
        src="@/assets/images/Unit/checks.jpg"
        alt="Diagram for app registration and data validation controls"
      />
      <figcaption>
        Diagram for app registration and data validation controls
      </figcaption>
    </figure>

    <p>
      The universal exchange rate between Unit currencies is using a blockchain
      smart-contract to provide real-time exchange rates.
    </p>

    <pre class="solidity-code"><code>
// SPDX-License-Identifier: MIT
// Pre-audit code for educational purposes only
pragma solidity ^0.8.0;

contract ExchangeRateCalculator {
    // Function to calculate the conversion rate and price in buyer coins
    function calculatePrice(
        uint256 buyerOrgUsers,
        uint256 sellerOrgUsers,
        uint256 priceInSellerCoins,
        uint256 totalBuyerCoins,
        uint256 totalSellerCoins
    ) public pure returns (uint256 conversionRate, uint256 priceInBuyerCoins) {
        require(buyerOrgUsers > 0, "Buyer organisation must have at least one user.");
        require(sellerOrgUsers > 0, "Seller organisation must have at least one user.");
        require(totalBuyerCoins > 0, "Buyer organisation must have a non-zero coin supply.");
        require(totalSellerCoins > 0, "Seller organisation must have a non-zero coin supply.");

        uint256 buyerPerCapita = totalBuyerCoins / buyerOrgUsers;
        uint256 sellerPerCapita = totalSellerCoins / sellerOrgUsers;

        conversionRate = (sellerPerCapita * 1e18) / buyerPerCapita;
        priceInBuyerCoins = (priceInSellerCoins * conversionRate) / 1e18;

        return (conversionRate, priceInBuyerCoins);
    }
}
  </code></pre>

    <h2 class="nomarginbottom">Considerations</h2>
    <p>
      In an era marked by economic turmoil, growing inequality and polarisation,
      the concept of a social contract becomes ever more pertinent. Its value
      lies in its focus on creating cohesion and trust in a landscape
      overwhelmed by fear, scepticism, and fragmentation. This need arises from
      the pressures on traditional social structures, which are struggling to
      adapt to the complex geopolitical, demographic, climatic, economic, and
      technological landscapes of the 21st century. However, developing an
      effective social contract by design, even a simple social contract, is a
      process that requires different perspectives, collaboration, and time. A
      well-designed social contract can bridge divides, creating a shared sense
      of purpose and commitment to the common good, whereas a poorly designed
      one can exacerbate divisions.
    </p>
    <p>
      History has demonstrated the impact of broken promises on public trust. To
      prevent further erosion of confidence, our social-contract workshops and
      initiatives have been spearheaded by key stakeholders with a deep
      understanding of the delicacy of public faith.
    </p>
    <p>
      Any engagement with communities should be approached with sensitivity and
      a clear intention not to over-promise or underdeliver. We should aim for
      openning dialogues and safe spaces for people to manifest their concerns
      and aspirations, setting realistic expectations and delivering on them to
      rebuild and enhance trust, to craft of a stronger, more resilient social
      tapestry together.
    </p>
    <p>
      The platform I've built during 2023 allows for an infinite number of
      organisational and monetary configurations. Unit is no longer just a coin;
      it's also not an alternative monetary model. Unit is a platform that can
      become a basis for a new monetary ecosystem that allows different monetary
      systems and social contracts to interact.
    </p>

    <!-- <p>
      I soon publish the database schema and platform code with an MIT open
      source licence, and publish the app documentation and the research behind
      it, and leave the smart contract for exchange rates on the blockchain for
      public use.
    </p> -->
    <p><em>To be continued...</em> :)</p>

    <!-- Image Space: Screenshots of the Unit platform in development. -->
  </div>
</template>

<script>
export default {
  props: ["content"],
};
</script>

<style scoped>
.row {
  gap: var(--regularMargin);
  align-items: start;
}
.solidity-code {
  position: relative;
  background-color: var(--backgroundD);
  padding: 0 1em;
  margin: 1em 0;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace !important;
  font-size: 0.9em;
  line-height: 1.4;
  border-radius: var(--borderRadiusRegular);
  height: 220px;
}

.solidity-code::-webkit-scrollbar {
  background-color: transparent;
  width: var(--smallestMargin);
  transform: translateY(5px);
}
.solidity-code::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}
</style>
