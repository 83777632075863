<template>
  <div class="design-process-container" ref="designProcessContainer">
    <div
      class="servicesHeader"
      :style="{
        left: isWideScreen ? '15px' : '',
      }"
    >
      <div>
        <h2 v-if="isWideScreen">Services</h2>
      </div>
      <div class="readingWidth">
        <h2>
          If you're building or improving a service or product, I can help at
          all phases.
        </h2>
      </div>
    </div>
    <div class="stage" id="DesignThinkingStage">
      <div
        class="fixedTop"
        id="fixedTop"
        :style="fixedLeftStyle"
        :class="stageClass"
      >
        <p>
          <span>{{ currentStage }}</span>
        </p>
      </div>
    </div>

    <div class="stepDescription">
      <div class="fixedCentre" id="fixedCentre" :style="fixedCentreStyle">
        <h3 v-html="currentStep"></h3>
      </div>
    </div>
    <div class="step" id="research">
      <img src="@/assets/images/DesignDiagram/research-w.png" alt="Research" />
      <span>Research</span>
    </div>
    <div class="step" id="insights">
      <img src="@/assets/images/DesignDiagram/insights-w.png" alt="Insights" />
      <span>Insights</span>
    </div>
    <div class="step" id="problem-scope">
      <img
        src="@/assets/images/DesignDiagram/problem-scope-w.png"
        alt="Problem Scope"
      />
      <span>Problem Scope</span>
    </div>
    <div class="step" id="opportunity-areas">
      <img
        src="@/assets/images/DesignDiagram/opportunity-areas-w.png"
        alt="Opportunity Areas"
      />
      <span>Opportunity Areas</span>
    </div>

    <div class="step" id="hmw">
      <img src="@/assets/images/DesignDiagram/hmw-w.png" alt="HMW" />
      <span>How might we</span>
    </div>
    <div class="step" id="ideation">
      <img src="@/assets/images/DesignDiagram/ideation-w.png" alt="Ideation" />
      <span>Ideation</span>
    </div>
    <div class="step" id="evaluation">
      <img
        src="@/assets/images/DesignDiagram/evaluation-w.png"
        alt="Evaluation"
      />
      <span>Evaluation</span>
    </div>
    <div class="step" id="designArtifacts">
      <img
        src="@/assets/images/DesignDiagram/user_journeys-w.png"
        alt="designArtifacts"
      />
      <span>designArtifacts</span>
    </div>
    <div class="step" id="product-design">
      <img
        src="@/assets/images/DesignDiagram/product-design-w.png"
        alt="Product Design"
      />
      <span>Product Design</span>
    </div>
    <div class="step" id="ship-alpha">
      <img
        src="@/assets/images/DesignDiagram/ship-alpha-w.png"
        alt="Ship Alpha"
      />
      <span>Ship Alpha {{ showRocketEmoji }}</span>
    </div>
    <div class="step" id="launch">
      <img src="@/assets/images/DesignDiagram/transp_2px.png" alt="Launch" />
      <span>Launch</span>
    </div>

    <!-- Conditional rendering for rocket emoji -->
    <div v-show="showRocketEmoji" class="step launch-shake" id="launch-shake">
      <img class="rocketImg" :src="rocketImg" />
    </div>

    <!-- Last interatciveimage intro -->
    <div class="" id="background-step">
      <hr />
      <p>Not all projects follow the exact same sequence.</p>

      <p>Which phase(s) do you think you need help with?</p>
      <p>Click to select</p>
      <InteractiveImgStages :is-wide-screen="isWideScreen" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
} from "vue";
import InteractiveImgStages from "@/components/ServicesComponents/InteractiveImgStages.vue";

const rocket = require("@/assets/images/Emojis/rocket_3d.png");

export default {
  name: "DesignProcess",
  components: {
    InteractiveImgStages,
  },
  props: {
    isWideScreen: Boolean,
    scrollContainer: Object,
  },
  setup(props) {
    const store = useStore();
    const steps = ref([]);
    const currentStage = ref("");
    const currentStep = ref("Scroll down");
    const showRocketEmoji = ref(false);
    const rocketImg = ref(require("@/assets/images/Emojis/rocket_3d.png"));

    const stageClass = computed(() => {
      switch (currentStage.value) {
        case "Designing the right thing":
          return "designing-right-thing-class";
        case "Designing things right":
          return "designing-things-right-class";
        case "🪄":
          return "magic-class";
        default:
          return "default-class";
      }
    });
    const designProcessContainer = ref(null);

    const stepMapping = {
      research: "Exploring the field",
      insights: "Finding patterns",
      "problem-scope": "Sizing up the problem",
      "opportunity-areas": "Identifying potentials",
      hmw: "Asking the right question",
      ideation: "Brainstorming <br>solutions",
      evaluation: "Chosing and developing <br>solutions",
      designArtifacts: "Creating key design documents",
      "product-design": "Prototype, build, <br>test, iterate",
      "ship-alpha": "Deploying trial",
      launch: "",
    };

    let lastScrollTop = 0; // Track the last scroll position

    const handleScroll = () => {
      const containerRect =
        designProcessContainer.value.getBoundingClientRect();
      let lastStepVisible = null;

      const currentScrollTop = designProcessContainer.value.scrollTop;
      // Determine scroll direction
      const isScrollingDown = currentScrollTop > lastScrollTop;
      lastScrollTop = currentScrollTop; // Update lastScrollTop for the next call

      steps.value.forEach((step, index) => {
        const rect = step.getBoundingClientRect();
        const stepEnter = containerRect.bottom - rect.top; // Distance from the bottom of the container to the top of the step
        const stepLeave = rect.bottom - containerRect.top; // Distance from the bottom of the step to the top of the container
        const direction = index % 2 === 0 ? "-100%" : "100%";
        const isFullyVisible = stepEnter > 0 && stepLeave > 0;

        if (stepEnter > 0 && stepLeave > 0) {
          const visibleRatio = Math.min(stepEnter / rect.height, 1);
          step.style.opacity = visibleRatio * 0.3;
          step.style.transform = `translateX(${
            parseFloat(direction) * (1 - visibleRatio)
          }%)`;

          if (visibleRatio > 0.9 && visibleRatio <= 1) {
            lastStepVisible = step;
          }
        } else {
          step.style.opacity = 0;
          step.style.transform = `translateX(${direction})`;
        }

        if (isScrollingDown) {
          if (step.id === "research" && isFullyVisible) {
            currentStage.value = "Designing the right thing";
            currentStep.value = stepMapping["research"]; // Update the current step when "research" becomes visible
          } else if (step.id === "ideation" && isFullyVisible) {
            currentStage.value = "Designing things right";
          } else if (step.id === "ship-alpha" && isFullyVisible) {
            showRocketEmoji.value = false;
          } else if (step.id === "launch" && isFullyVisible) {
            currentStage.value = " ";
            showRocketEmoji.value = true;
          }
        } else {
          // Logic for scrolling up

          if (step.id === "research" && isFullyVisible) {
            currentStage.value = "";
            currentStep.value = "Scroll down";
          } else if (step.id === "ideation" && isFullyVisible) {
            currentStage.value = "Designing the right thing";
          } else if (step.id === "launch" && isFullyVisible) {
            currentStage.value = "Designing things right";
            showRocketEmoji.value = false;
          }
        }
      });

      if (lastStepVisible) {
        const stepId = lastStepVisible.id;
        currentStep.value = stepMapping[stepId];
      }

      lastScrollTop = designProcessContainer.value.scrollTop;
    };

    const fixedCentreStyle = computed(() => {
      if (props.isWideScreen) {
        return {
          width: "calc(25%)",
          left: "25%",
        };
      } else {
        return {
          width: "calc(100%)",
          top: "calc(50vh)",
          left: "0",
        };
      }
    });

    const fixedLeftStyle = computed(() => {
      if (props.isWideScreen) {
        return {
          left: "-25%",
        };
      } else {
        return {
          left: "-50.2%",
        };
      }
    });

    /* Styling the background */
    const changeBodyBackground = (newStage) => {
      let gradient;
      switch (newStage) {
        case "Designing the right thing":
          gradient =
            "linear-gradient(to right, var(--backgroundD), var(--backgroundD))";
          break;
        case "Designing things right":
          gradient =
            "linear-gradient(to right, var(--backgroundD),  var(--backgroundD), var(--backgroundD))";
          break;
        case "🪄":
          gradient = "var(--backgroundD)";
          break;
        default:
          gradient = "var(--backgroundD)";
          break;
      }
      document.body.style.background = gradient;
    };

    watch(currentStage, (newStage) => {
      changeBodyBackground(newStage);
    });

    onMounted(() => {
      nextTick(() => {
        steps.value = Array.from(
          designProcessContainer.value.querySelectorAll(".step")
        );
        designProcessContainer.value.addEventListener("scroll", handleScroll);
        console.log("Scroll event listener atached");
      });
    });

    onBeforeUnmount(() => {
      if (designProcessContainer.value) {
        designProcessContainer.value.removeEventListener(
          "scroll",
          handleScroll
        );
        console.log("Scroll event listener detached");
      }
    });

    return {
      designProcessContainer,
      steps,
      currentStage,
      currentStep,
      showRocketEmoji,
      rocketImg,
      fixedCentreStyle,
      fixedLeftStyle,
      stageClass,
    };
  },
};
</script>

<style scoped>
.design-process-container {
  position: relative;
  height: -webkit-fill-available;
  /* color: var(--backgroundD); */
  padding: var(--regularMargin);
  overflow-x: clip;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 3%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0.4) 100%
  );
}

.servicesHeader {
  position: absolute;
  top: 0px;
  left: 0;
}

.design-process-container::-webkit-scrollbar {
  background-color: var(--backgroundD);
  width: var(--smallestMargin);
}
.design-process-container::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}
.stepDescription {
  position: relative;
  /* border: 1px solid red; */
  width: 100%;
  height: 20px;
}

.step {
  position: absolute;
  text-align: center;
  padding: var(--regularMargin);
  color: transparent;
  opacity: 0.2;
  transition: transform var(--regularTransitionSpeed) ease-in-out,
    opacity var(--regularTransitionSpeed) ease-in-out;
  width: 80%;
  height: 100px;
}

/* images design thinking */

/* Alternating steps with incremental lowering */

/* Initial off-screen positions */
#research,
#problem-scope,
#hmw,
#product-design,
#evaluation {
  transform: translateX(-100%);
  left: 5%;
}

#insights,
#opportunity-areas,
#ideation,
#designArtifacts,
#ship-alpha {
  transform: translateX(100%);
  right: 5%;
}

#research {
  top: calc(var(--step-spacing) * 1.3);
}

#insights {
  top: calc(var(--step-spacing) * 2.3);
}

#problem-scope {
  top: calc(var(--step-spacing) * 3.3);
}

#opportunity-areas {
  top: calc(var(--step-spacing) * 4.3);
}

#hmw {
  top: calc(var(--step-spacing) * 5.3);
}

#ideation {
  right: 0;
  top: calc(var(--step-spacing) * 6.3);
}

#evaluation {
  top: calc(var(--step-spacing) * 7.3);
}

#designArtifacts {
  top: calc(var(--step-spacing) * 8.3);
  width: 300px;
}

#product-design {
  top: calc(var(--step-spacing) * 9.3);
}

#ship-alpha {
  top: calc(var(--step-spacing) * 10.3);
}

#launch {
  top: calc(var(--step-spacing) * 11.3);
  z-index: 2;
  pointer-events: none;
  height: 2px;
}

#launch-shake {
  top: calc(var(--step-spacing) * 11.3);
  z-index: 2;
  pointer-events: none;
  /* border: 5px solid red; */
  height: max-content;
}
#background-step {
  max-height: 100vh;
  width: 100%;
  left: 0;
  top: calc(var(--step-spacing) * 11.7);
  position: absolute;
  text-align: center;
  color: var(--foregroundD);
  z-index: 1;
}

#background-step img {
  max-width: 100% !important;
  width: auto;
  max-height: 90vh;
  opacity: 0.8;
  z-index: 1;
}

#fixedCentre {
  width: calc(25%);
  top: calc(50vh); /* Adjust this based on your total number of steps */
  left: 25%;
  position: fixed;
  text-align: center;
  pointer-events: none;
  color: var(--foregroundD);
  z-index: 1;
  transform: translateY(-50%);
}

/* .stage {
  position: relative;
  width: 100%;
  height: 100vh;
  border: 1px solid red;
} */
#fixedTop {
  width: calc(100%);
  top: calc(50vh);
  left: -50%;
  position: fixed;
  text-align: center;
  pointer-events: none;
  color: var(--foregroundD);
  z-index: 3;
  transition: all var(--regularTransitionSpeed) ease-in-out;
  transform: rotate(-90deg)
    translate(var(--smallClickHeight), var(--smallClickHeight));
}

#rocketemoji {
  position: absolute;
  bottom: 0; /* Adjusted to ensure it's within the viewport */
  right: 0; /* Ensure it's positioned to the right within the viewport */
  /* Make sure the element is not placed outside the visible area */
}

.rocketImg {
  width: 100px;
  z-index: 3;
  filter: drop-shadow(0px 2px 8px var(--backgroundD_darker));
}

/* SMALL DEVICES */

@media screen and (max-width: 768px) {
  /* #research {
      width: 80%;
    } */
  .design-process-container {
    padding: var(--smallMargin);
  }
  #background-step img {
    max-width: 100%;
    height: auto;
  }

  .step {
    width: 250px !important;
  }
  .rocketImg {
    width: 75px;
    z-index: 3;
    filter: drop-shadow(0px 2px 8px var(--backgroundD_darker));
  }
}

@media screen and (max-width: 1400px) {
  .step {
    width: 350px;
  }
}

/* STAGE CLASSES */
.designing-right-thing-class p {
  transition: all var(--regularTransitionSpeed) ease-in-out;
  text-shadow: -1px -2px 5px var(--backgroundD_lightest),
    2px 2px 8px var(--backlightLowLower), 0px 3px 12px var(--backgroundD_darker);
}

.designing-things-right-class p {
  transition: all var(--regularTransitionSpeed) ease-in-out;

  text-shadow: -1px -2px 5px var(--backgroundD_darker),
    2px 2px 8px rgba(254, 3, 3, 0.6), 0px 3px 12px var(--complementary);
}

.magic-class p {
  transition: all var(--regularTransitionSpeed) ease-in-out;
  text-shadow: -1px -2px 8px var(--backgroundD_darker),
    -2px 2px 8px rgb(167, 216, 138), 0px 3px 12px var(--backgroundD_darker);
}

.default-class p {
  transition: all var(--regularTransitionSpeed) ease-in-out;

  /* default styles if needed */
}

/* ANIMATIONS */
.launch-shake {
  animation: shake 1s ease-in forwards;
  pointer-events: none;
}

@keyframes shake {
  0% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg);
  }
  5% {
    transform: translate(3px, 0px) rotate(5deg);
  }
  10% {
    transform: translate(-3px, 0px) rotate(-5deg);
  }
  15% {
    opacity: 1;
    transform: translate(3px, 0px) rotate(5deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(-5deg);
  }
  25% {
    transform: translate(3px, 0px) rotate(5deg);
  }
  30% {
    transform: translate(-3px, 0px) rotate(-5deg);
  }
  35% {
    transform: translate(3px, 0px) rotate(4deg);
  }
  40% {
    transform: translate(-3px, 0px) rotate(-4deg);
  }
  45% {
    transform: translate(3px, 0px) rotate(4deg);
  }

  50% {
    transform: translate(0px, 0px) rotate(0deg);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(700px, -700px) rotate(0deg);
  }
}
</style>
