<template>
  <div class="timeline" :class="{ 'alternate-timeline': isAlternateVersion }">
    <div class="row">
      <img v-if="icon" :src="icon" class="timelineIcon" alt="Timeline icon" />
      <h3 class="nomarginbottom">{{ title }}</h3>
    </div>
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <strong>{{ item.title }}:</strong>
        {{ item.description }}
        <div class="lastTimelineRow">
          <small>{{ item.dateRange }}</small>
          <button
            v-if="item.hasButton"
            class="smallButton ghost smallmargintopbottom noSideMargins"
            @click="() => emitItemSelect(item.title)"
          >
            <small>Details</small>
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";

export default {
  name: "TimelineComponent",
  props: {
    title: String,
    icon: String, // Ensure this is a String to accept the image path
    items: Array,
  },
  setup(props, { emit }) {
    const store = useStore();
    const isAlternateVersion = computed(() => store.state.isAlternateVersion);
    const emitItemSelect = (title) => {
      emit("onItemSelect", title);
    };
    return { isAlternateVersion, emitItemSelect };
  },
};
</script>

<style scoped>
.row {
  gap: var(--smallerMargin);
}

.timelineIcon {
  font-size: 2rem;
  width: var(--smallClickHeight);
  /* filter: drop-shadow(0px 2px 8px var(--backgroundD_darker)); */
  margin-top: var(--regularMargin);
}

.timeline ul {
  list-style-type: none;
  width: calc(100% - var(--smallMargin));
  padding: 0;
  margin: 0;
  border-left: 2px solid var(--foregroundD);
  margin-left: var(--smallerMargin);
  transform: translateX(var(--smallMargin));
  border-bottom-left-radius: var(--smallMargin);
}

.timeline ul li {
  margin-top: var(--smallerMargin) !important;
  padding-left: var(--regularMargin) !important;
  position: relative;
  padding-top: var(--smallMargin) !important;
}

/* circle */
.timeline ul li::before {
  transform: translateY(-var(--smallMargin));
  content: "";
  position: absolute;
  left: 4px; /* Align with the left border */
  top: var(--smallMargin);
  transform: translate(-100%, 50%); /* Adjust the position to align correctly */
  width: var(--smallMargin);
  height: var(--smallMargin);
  border-radius: 50%;
  background-color: var(--foregroundD);
}

.lastTimelineRow {
  display: flex;
  align-items: center;
  gap: var(--smallerMargin);
}

.alternate-timeline ul {
  border-left: 2px solid var(--foregroundD);
}

.alternate-timeline ul li::before {
  background-color: var(--foregroundD) !important;
}

@media screen and (max-width: 768px) {
  .timelineIcon {
    font-size: 1.55rem;
  }
}
</style>
