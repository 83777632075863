<template>
  <div class="medium-feed masonry-layout">
    <div v-for="article in articles" :key="article.guid" class="article">
      <img
        :src="getFirstImage(article.description)"
        class="article-image"
        v-if="getFirstImage(article.description)"
      />
      <h4 class="article-title smallmargintopnomarginbottom">
        {{ article.title }}
      </h4>

      <p class="article-description">
        {{ getFirstWords(article.description, 10) }}...
        <a :href="article.link" target="_blank" class="read-more">
          Read
          <svg class="microSvg">
            <use
              xlink:href="@/assets/icons/iconset.svg#microExternalLink"
            ></use>
          </svg>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import rssFeed from "@/components/InsightsComponents/rssFeed.json";

export default {
  name: "MediumFeed",
  setup() {
    const articles = ref(rssFeed.items);

    function getFirstWords(htmlString, wordLimit) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;
      let text = tempDiv.textContent || tempDiv.innerText || "";
      text = removeKeyTakeaway(text);
      let words = text.split(" ", wordLimit);
      return words.join(" ");
    }

    function getFirstImage(htmlString) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlString;
      let firstImage = tempDiv.querySelector("figure img");
      if (!firstImage) {
        firstImage = tempDiv.querySelector("img");
      }
      return firstImage ? firstImage.src : "";
    }

    function removeKeyTakeaway(text) {
      return text.replace(/key takeaway:\s*/i, "");
    }

    return {
      articles,
      getFirstWords,
      getFirstImage,
    };
  },
};
</script>

<style scoped>
img {
  opacity: 0.8;
  background-color: white;
}

.article-image {
  max-height: 150px;
  width: 100%; /* This ensures the image is responsive and maintains aspect ratio */
  object-fit: cover; /* This will crop the image to fit the container */
  object-position: center; /* This will center the image within the container */
  opacity: 0.55;
}

@media screen and (max-width: 768px) {
  .article-image {
    max-height: 130px;
  }
}
</style>
