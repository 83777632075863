<template>
  <div class="centredCol" ref="parentContainer">
    <div v-if="isWideScreen || isMobile" class="image-steps-container mobile">
      <!-- Render images for mobile -->

      <img src="@/assets/images/DesignDiagram/design_thinking_jam_0.png" />
      <img
        v-for="(imageSrc, index) in mobileImages"
        :key="'mobile-step-' + index"
        :src="imageSrc"
        @click="toggleImage(index)"
        :class="{
          embossed: !clickedImages.includes(index),
          pressed: clickedImages.includes(index),
        }"
      />
      <img src="@/assets/images/DesignDiagram/design_thinking_jam_5.png" />
    </div>
    <div v-else class="image-steps-container desktop">
      <!-- Render images for desktop -->
      <img src="@/assets/images/DesignDiagram/design_thinking_jam_hor_0.png" />
      <img
        v-for="(imageSrc, index) in desktopImages"
        :key="'mobile-step-' + index"
        :src="imageSrc"
        @click="toggleImage(index)"
        :class="{
          embossed: !clickedImages.includes(index),
          pressed: clickedImages.includes(index),
        }"
      />

      <img src="@/assets/images/DesignDiagram/design_thinking_jam_hor_5.png" />
    </div>

    <!-- Simple contact form -->

    <form @submit.prevent="submitContact">
      <div
        class="display-phases"
        :style="{ opacity: selectedPhases.length ? 1 : 0 }"
      >
        Possible phases involved: {{ selectedPhases.join(", ") }}
      </div>
      <button type="submit">Get in touch</button>
    </form>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";

export default {
  name: "InteractiveImageStages",
  props: {
    isWideScreen: Boolean,
  },
  setup() {
    const parentContainer = ref(null); // Reference to the parent container

    const isMobile = ref(false); // Initially set to false
    const mobileImages = ref([
      require("@/assets/images/DesignDiagram/design_thinking_jam_1.png"),
      require("@/assets/images/DesignDiagram/design_thinking_jam_2.png"),
      require("@/assets/images/DesignDiagram/design_thinking_jam_3.png"),
      require("@/assets/images/DesignDiagram/design_thinking_jam_4.png"),
    ]);

    const desktopImages = ref([
      require("@/assets/images/DesignDiagram/design_thinking_jam_hor_1.png"),
      require("@/assets/images/DesignDiagram/design_thinking_jam_hor_2.png"),
      require("@/assets/images/DesignDiagram/design_thinking_jam_hor_3.png"),
      require("@/assets/images/DesignDiagram/design_thinking_jam_hor_4.png"),
    ]);

    const clickedImages = ref([]);
    const phaseNames = ["Discovery", "Definition", "Development", "Delivery"];

    // Method to determine the gradient based on clicked images
    const determineGradient = () => {
      if (clickedImages.value.some((index) => index < 2)) {
        // If any of the first two images are clicked
        return "linear-gradient(to right, var(--backlightLowLowestA), var(--backlightLowLowest))";
      } else if (clickedImages.value.some((index) => index >= 2)) {
        // If any of the last two images are clicked
        return "linear-gradient(to right, var(--complementaryLow), var(--primaryLow), var(--complementaryLowest))";
      }
      return "var(--backgroundD)"; // Default gradient
    };
    const gradient = computed(determineGradient);

    const selectedPhases = computed(() => {
      return clickedImages.value.map((index) => phaseNames[index]);
    });

    const contactMessage = ref("");

    const toggleImage = (index) => {
      if (!clickedImages.value.includes(index)) {
        clickedImages.value.push(index);
      } else {
        clickedImages.value.splice(clickedImages.value.indexOf(index), 1);
      }
      determineGradient();
    };

    const contactEmail = ref("");
    const submitContact = () => {
      let emailBody =
        "Hello João, \n\n(let me know why you're getting in touch here)";
      if (selectedPhases.value.length > 0) {
        emailBody += `\n\n—\nPossible phases involved: ${selectedPhases.value.join(
          ", "
        )}`;
      }

      const mailtoLink = `mailto:joaoalvesmarrucho@gmail.com?subject=Contact%20from%20Website&body=${encodeURIComponent(
        emailBody
      )}`;
      window.open(mailtoLink, "_blank");
    };

    onMounted(() => {
      // Add a listener to handle changes in the parent container's width
      window.addEventListener("resize", updateIsMobile);
      // Initially set the isMobile computed property
      updateIsMobile();
    });

    // Function to update the isMobile computed property based on the parent container's width
    const updateIsMobile = () => {
      // console.log("updateIsMobile");
      // console.log(parentContainer.value);
      if (parentContainer.value) {
        const parentWidth = parentContainer.value.offsetWidth;
        isMobile.value = parentWidth <= 768;
        console.log("isMobile", isMobile.value);
      }
    };

    return {
      parentContainer,
      isMobile,
      mobileImages,
      desktopImages,
      toggleImage,
      contactEmail,
      clickedImages,
      selectedPhases,
      submitContact,
      gradient,
      toggleImage,
    };
  },
};
</script>

<style scoped>
.centredCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-steps-container {
  display: flex;
  width: 100%;
  overflow: visible;
  justify-content: center;
}

.image-steps-container.mobile {
  flex-direction: column;
  overflow: visible;
  max-width: 100%;
}

.image-steps-container.desktop {
  flex-direction: row;
}

.image-steps-container img {
  opacity: 0.8;
  border-radius: 0px;
}

.image-steps-container.desktop img {
  flex: 0 0;
  max-width: calc(100% / 6);
  height: 74vh;
  object-fit: contain;
  transition: all 0.05s ease;
}

.image-steps-container.desktop img.embossed:hover {
  opacity: 1;
}

.image-steps-container.desktop,
.image-steps-container.mobile {
  overflow: visible;
  box-sizing: border-box;
}

.embossed {
  /* border: 1px dashed var(--foregroundD); */
  transition: box-shadow 0.3s ease;
}

.pressed {
  background: linear-gradient(
    to bottom,
    var(--backgroundD_lighter_transptest) 8%,
    var(--backgroundD_lighter_transptest) 16%,
    var(--primary) 50%,
    var(--backgroundD_lighter_transptest) 84%,
    var(--backgroundD_lighter_transptest) 88%
  );
  opacity: 1 !important;
}

.mobile .pressed {
  background: linear-gradient(
    to right,
    var(--backgroundD) 5%,
    var(--backgroundD_lighter) 12%,
    var(--primary) 50%,
    var(--backgroundD_lighter) 82%,
    var(--backgroundD) 90%
  );
  opacity: 1 !important;
}

.display-phases {
  max-width: 88%;
  background-color: var(--backgroundD_lighter);
  border: 1px solid var(--backgroundD_lightest);
  padding: 10px;
  border-radius: var(--smallMargin);
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media screen and (max-width: 768px) {
  .display-phases {
    width: 98%;
  }
}
</style>
