<template>
  <div class="services" ref="servicesContainer">
    <DesignProcess
      :is-wide-screen="isWideScreen"
      :scroll-container="servicesContainer"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";

import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import DesignProcess from "@/components/LandingComponents/DesignProcess.vue";

export default {
  name: "Services",
  components: {
    DesignProcess,
  },
  props: {
    isWideScreen: Boolean,
  },
  setup(props, context) {
    const store = useStore();

    const servicesContainer = ref(null);

    const handleScrollService = () => {
      const threshold = 50; // height of the navigation bar or any other value you choose
      const isAboveThreshold = servicesContainer.value.scrollTop > threshold;

      const shouldHideNav = servicesContainer.value.scrollTop > threshold;

      store.commit("setNavVisibility", !shouldHideNav);
    };

    onMounted(() => {
      console.log("mounted");
      console.log("servicesContainer: ", servicesContainer.value);

      if (servicesContainer.value) {
        servicesContainer.value.addEventListener("scroll", handleScrollService);
      } else {
        console.log("no services container");
      }
    });

    onUnmounted(() => {
      if (servicesContainer.value) {
        servicesContainer.value.removeEventListener(
          "scroll",
          handleScrollService
        );
      }
    });

    return {
      servicesContainer,
      handleScrollService,
    };
  },
};
</script>

<style scoped>
.services {
  width: 100%;
  height: calc(
    100vh - var(--smallClickHeight) - var(--regularMargin) -
      var(--regularMargin)
  );
}
</style>
