<template>
  <div class="about">
    <h2 v-if="isWideScreen">About</h2>

    <div class="readingWidth">
      <Timeline
        title=""
        :icon="hammerAndWrenchImg"
        :items="professionalExperienceItems"
        @onItemSelect="handleItemSelected"
      />
      <Timeline
        title=""
        :icon="artistPaletteImg"
        :items="artItems"
        @onItemSelect="handleItemSelected"
      />
      <Timeline
        title=""
        :icon="moneyBagImg"
        :items="moneyItems"
        @onItemSelect="handleItemSelected"
      />
      <Timeline title="" :icon="manTeacherImg" :items="educationItems" />

      <Drawer
        :isVisible="isDrawerVisible"
        :selectedItem="selectedItem"
        @update:isVisible="isDrawerVisible = $event"
      >
        <component :is="currentComponent" :content="currentContent" />
      </Drawer>
    </div>
    <br />
  </div>
</template>

<script>
import { computed, ref, shallowRef, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const artistPalette = require("@/assets/images/Emojis/artist_palette_3d.png");
const hammerAndWrench = require("@/assets/images/Emojis/hammer_and_wrench_3d.png");
const magnifyingGlass = require("@/assets/images/Emojis/magnifying_glass_tilted_left_3d.png");
const manTeacher = require("@/assets/images/Emojis/man_teacher_3d_default.png");
const moneyBag = require("@/assets/images/Emojis/money_bag_3d.png");

import Timeline from "@/components/AboutComponents/Timeline.vue";
import Drawer from "@/components/GenericComponents/Drawer.vue";
import EYServiceDesigner from "@/components/DrawerComponents/EYServiceDesigner.vue";
import UnitMonetaryModel from "@/components/DrawerComponents/UnitMonetaryModel.vue";
import Solopreneurship from "@/components/DrawerComponents/Solopreneurship.vue";
import GraphicDesign from "@/components/DrawerComponents/GraphicDesign.vue";

export default {
  name: "AboutView",
  components: {
    Timeline,
    Drawer,
    EYServiceDesigner,
    UnitMonetaryModel,
    Solopreneurship,
    GraphicDesign,
  },
  props: {
    isWideScreen: Boolean,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isAlternateVersion = computed(() => store.state.isAlternateVersion);
    const isDrawerVisible = ref(false);
    const selectedItem = ref("");
    const currentComponent = shallowRef(null);
    const currentContent = ref(null);

    const artistPaletteImg = artistPalette;
    const hammerAndWrenchImg = hammerAndWrench;
    const magnifyingGlassImg = magnifyingGlass;
    const manTeacherImg = manTeacher;
    const moneyBagImg = moneyBag;

    const professionalExperienceItems = ref([
      {
        title: "Service Design",
        description: "Specialised in end-to-end user experience design to create or change service propositions.",
        dateRange: "2019 - Now",
        hasButton: true,
      },
      {
        title: "Solopreneurship and independent contractor",
        description: "Developing services and design sytems. Instrumental at all stages, from strategy to delivery.",
        dateRange: "2008 - Now",
        hasButton: true,
      },
      {
        title: "Graphic Design",
        description: "Creating visual identities and delivering graphic materials for brands.",
        dateRange: "2005 - Now",
        hasButton: true,
      },
    ]);

    const moneyItems = ref([
      {
        title: "Unit, a new value exchange ecosystem",
        description: "Creating and implementing new value exchange ecosystem with a fair exchange rate.",
        dateRange: "2008 - Now",
        hasButton: true,
      },
    ]);

    const educationItems = ref([
      {
        title: "Royal College of Art",
        description: "MA Service Design",
        dateRange: "2019 - 2021",
      },
      {
        title: "Central Saint Martins",
        description: "Transitioned from Digital media specialist to leading the digital media department.",
        dateRange: "2011 - department lead in 2019",
      },
      {
        title: "University of the Arts London",
        description: "PgCert Academic Practice in Art, Design and Communication",
        dateRange: "2016 - 2017",
      },
      {
        title: "London Academy of IT",
        description: "Advanced JavaScript with Node.js",
        dateRange: "2017",
      },
      {
        title: "University of Porto – FBAUP",
        description: "MA/Hons Communication Design",
        dateRange: "2001 - 2006",
      },
    ]);

    const artItems = ref([
      {
        title: "Lófte – Porto's First Co-working Space",
        description: "Redesign and rephurbishment and inhouse leadership.",
        dateRange: "2008 - 2013",
      },
      {
        title: "'New Emotion' Art Movement",
        description: "Exploring alternatives to minimal trends. Part of music collectives and record labels Àstato, Internet Bravo, and Concorrência.",
        dateRange: "2003 - 2008",
      },
    ]);

    const handleItemSelected = (itemTitle) => {
      selectedItem.value = itemTitle;
      isDrawerVisible.value = true;
      router.push({ name: "about", params: { itemTitle } });
    };

    const updateDrawerContent = (itemTitle) => {
      switch (itemTitle) {
        case "Service Design":
          currentComponent.value = EYServiceDesigner;
          break;
        case "Unit, a new value exchange ecosystem":
          currentComponent.value = UnitMonetaryModel;
          break;
        case "Solopreneurship and independent contractor":
          currentComponent.value = Solopreneurship;
          break;
        case "Graphic Design":
          currentComponent.value = GraphicDesign;
          break;
        default:
          currentComponent.value = null;
      }
    };

    watch(
      () => route.params.itemTitle,
      (newItemTitle) => {
        if (newItemTitle) {
          selectedItem.value = newItemTitle;
          isDrawerVisible.value = true;
          updateDrawerContent(newItemTitle);
        } else {
          isDrawerVisible.value = false;
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      if (route.params.itemTitle) {
        updateDrawerContent(route.params.itemTitle);
      }
    });

    return {
      isAlternateVersion,
      professionalExperienceItems,
      educationItems,
      moneyItems,
      artItems,
      isDrawerVisible,
      selectedItem,
      currentComponent,
      currentContent,
      handleItemSelected,
      artistPaletteImg,
      hammerAndWrenchImg,
      magnifyingGlassImg,
      manTeacherImg,
      moneyBagImg,
    };
  },
};
</script>

<style scoped></style>
